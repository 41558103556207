<template>
  <a-modal
    :title="title"
    :width="900"
    :visible="visible"
    :confirmLoading="confirmLoading"
    okText="确认"
    cancelText="取消"
    @ok="handleSubmit"
    @cancel="handleCancel"
  >

    <a-spin :spinning="confirmLoading">
      <a-form :form="form">

        <!--        <a-form-item-->
        <!--          style="display: none;"-->
        <!--          :labelCol="labelCol"-->
        <!--          :wrapperCol="wrapperCol"-->
        <!--        >-->
        <!--          <a-input v-decorator="['id']" />-->
        <!--        </a-form-item>-->

        <a-form-item
          label="父级名称"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
        >
          <a-tree-select
            v-decorator="['parentId', {rules: [{required: true, message: '请选择父级名称'}]}]"
            style="width: 100%"
            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
            :tree-data="treeData"
            :replaceFields="{children:'children', title:'name', key:'key', value: 'key' }"
            placeholder="请选择父级名称"
            tree-default-expand-all
          >
          </a-tree-select>
        </a-form-item>

        <a-form-item
          label="类型名称"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
        >
          <a-input
            placeholder="请输入类型名称"
            v-decorator="['name', {rules: [{required: true, message: '请输入类型名称！'}]}]"
          />
        </a-form-item>

        <a-form-item
          label="排序号"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
        >
          <a-input-number
            placeholder="排序号"
            v-decorator="['sort', {rules: [{required: true, message: '请输入排序号！'}],initialValue:0}]"
          />
        </a-form-item>

        <a-form-item
          type="textarea"
          label="备注"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
          :rows="4"
        >
          <a-input
            placeholder="请输入备注"
            v-decorator="['remark']"
            type="textarea"
            :rows="4"
          />
        </a-form-item>

      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import { getDirTree, editDir, addDir } from '@/api/system/parameter'

export default {
  data () {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 15 }
      },
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
      treeData: [],
      // 当前类型 1编辑 0新增
      modalType: undefined,
      // 编辑id
      id: undefined,
      // 编辑key
      key: undefined,
      //  标题
      title: undefined
    }
  },
  methods: {
    /**
     * 编辑
     */
    edit (record) {
      this.title = '编辑参数分类'
      this.modalType = true
      this.id = record.id
      this.key = record.key
      this.handleDirTree()
      this.visible = true
      setTimeout(() => {
        this.form.setFieldsValue({
          name: record.name,
          parentId: record.parentId,
          remark: record.remark,
          sort: record.sort
        })
      }, 100)
    },
    /**
     * 添加
     */
    append () {
      this.title = '新增参数分类'
      this.modalType = false
      this.handleDirTree()
      this.visible = true
    },
    /**
     * 提交
     */
    handleSubmit () {
      const { form: { validateFields } } = this
      this.confirmLoading = true
      validateFields((errors, values) => {
        if (!errors) {
          if (this.modalType) {
            this.editSubmit(values)
          } else {
            this.addSubmit(values)
          }
        } else {
          this.confirmLoading = false
        }
      })
    },
    /**
     *编辑提交
     */
    editSubmit (values) {
      editDir(Object.assign(values, { id: this.id })).then((res) => {
        this.confirmLoading = false
        if (res.code === 200) {
          this.$message.success('编辑成功')
          this.handleCancel()
          this.$emit('ok', values)
        }
      }).finally((res) => {
        this.confirmLoading = false
      })
    },

    /**
     * 添加提交
     */
    addSubmit (values) {
      addDir(values).then((res) => {
        this.confirmLoading = false
        if (res.code === 200) {
          this.$message.success('新增成功')
          this.handleCancel()
          this.$emit('ok', values)
        }
      }).finally((res) => {
        this.confirmLoading = false
      })
    },

    handleCancel () {
      this.form.resetFields()
      this.visible = false
      this.modalType = undefined
      this.id = undefined
      this.title = undefined
    },
    /**
     * taidi
     * 获取组织树信息
     */
    handleDirTree () {
      this.loading = true
      getDirTree({ parentId: 0 }).then((res) => {
        if (res['code'] === 200) {
          if (this.modalType) {
            this.deleteThisDept(res.data)
          }
          this.treeData = [{ name: '顶部节点', key: 0, children: null }]
          this.treeData[0].children = res.data
        }
      }).finally(() => {
        this.loading = false
      })
    },
    /**
     * 删除当前部门
     */
    deleteThisDept (data) {
      for (let i = 0; i < data.length; i++) {
        if (data[i].key === this.key) {
          delete data[i]
        }
        if (data[i] && data[i].children) {
          this.deleteThisDept(data[i].children)
        }
      }
    }
  }
}
</script>
