<template>
  <a-modal
    :title="title"
    :width="900"
    :visible="visible"
    :confirmLoading="confirmLoading"
    okText="确认"
    cancelText="取消"
    @ok="handleSubmit"
    @cancel="handleCancel">

    <a-spin :spinning="confirmLoading">
      <a-form :form="form">
        <a-form-item
          label="参数名称"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
        >
          <a-input placeholder="请输入参数名称" v-decorator="['name', {rules: [{required: true, message: '请输入参数名！'}]}]" />
        </a-form-item>

        <a-form-item
          label="参数键名"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
        >
          <a-input
            placeholder="请输入参数键名"
            v-decorator="['keyName', {rules: [{required: true, message: '请输入参数键名！'}]}]" />
        </a-form-item>

        <a-form-item
          label="参数键值"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
        >
          <a-input placeholder="请输入参数键值" v-decorator="['value', {rules: [{required: true, message: '请输入参数键值！'}]}]" />
        </a-form-item>

        <a-form-item
          label="默认类型"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
        >
          <a-tree-select
            v-decorator="['dirId', {rules: [{required: true, message: '请选择参数类型'}]}]"
            style="width: 100%"
            :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
            :tree-data="treeData"
            :replaceFields="{children:'children', title:'name', key:'key', value: 'key' }"
            placeholder="请选择参数类型"
            tree-default-expand-all
          >
          </a-tree-select>
        </a-form-item>
        <a-form-item
          label="排序号"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
        >
          <a-input-number
            placeholder="排序号"
            v-decorator="['sort', {rules: [{required: true, message: '请输入排序号！'}],initialValue:0} ]"/>
        </a-form-item>

        <a-form-item
          label="备注"
          :labelCol="labelCol"
          :wrapperCol="wrapperCol"
        >
          <a-input
            placeholder="请输入备注"
            v-decorator="['remark']"
            type="textarea"
            :rows="4"/>
        </a-form-item>
        <!--            v-decorator="['remark', {rules: [{required: true, message: '请输入备注！'}]}]" />-->
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import { getDirTree, add, edit } from '@/api/system/parameter'

export default {
  data () {
    return {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 5 }
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 15 }
      },
      visible: false,
      confirmLoading: false,
      form: this.$form.createForm(this),
      // 规则树信息
      treeData: [],
      //  等待加载
      loading: false,
      title: undefined,
      // 只有编辑才有
      id: undefined
    }
  },
  methods: {
    /**
     * 新增
     */
    add (record) {
      this.title = '新增参数记录'
      this.handleDeptTree()
      this.visible = true
      setTimeout(() => {
        this.form.setFieldsValue({
          dirId: record
        })
      }, 100)
    },
    /**
     * 编辑
     */
    edit (record) {
      this.title = '编辑参数记录'
      this.handleDeptTree()
      this.visible = true
      this.id = record.id
      console.log(this.id)
      console.log(record)
      setTimeout(() => {
        this.form.setFieldsValue({
          name: record.name,
          keyName: record.keyName,
          value: record.value,
          remark: record.remark,
          sort: record.sort,
          dirId: record.dirId
        })
      }, 100)
    },
    handleSubmit () {
      const { form: { validateFields } } = this
      this.confirmLoading = true
      validateFields((errors, values) => {
        if (!errors) {
          if (this.id) {
            this.editSubmit(Object.assign(values, { id: this.id }))
          } else {
            this.addSubmit(values)
          }
        } else {
          this.confirmLoading = false
        }
      })
    },
    /**
     *新增提交
     */
    addSubmit (values) {
      add(values).then((res) => {
        this.confirmLoading = false
        if (res.code === 200) {
          this.$message.success('新增成功')
          this.handleCancel()
          this.$emit('ok', values)
        } else {
          this.$message.info('新增失败：' + res.msg)
        }
      }).finally((res) => {
        this.confirmLoading = false
      })
    },
    /**
     *编辑提交
     */
    editSubmit (values) {
      edit(values).then((res) => {
        this.confirmLoading = false
        if (res.code === 200) {
          this.$message.success('编辑成功')
          this.handleCancel()
          this.$emit('ok', values)
        } else {
          this.$message.info('编辑失败：' + res.msg)
        }
      }).finally((res) => {
        this.confirmLoading = false
      })
    },
    /**
     * 取消并关闭
     */
    handleCancel () {
      this.form.resetFields()
      this.visible = false
      this.title = undefined
      this.id = undefined
    },
    /**
     * taidi
     * 获取组织树信息
     */
    handleDeptTree (id) {
      this.loading = true
      getDirTree({ parentId: 0 }).then((res) => {
        if (res['code'] === 200) {
          this.treeData = res.data
        }
      }).finally(() => {
        this.loading = false
      })
    }
  }
}
</script>
