import { axios } from '@/utils/request'

/**
 * 增加参数分类
 *
 * @author taidi
 * @date 2021/02/23 15:10
 */
export function addDir (parameter) {
  return axios({
    url: '/param/addDir',
    method: 'post',
    data: parameter
  })
}

/**
 * 增加参数
 *
 * @author taidi
 * @date 2021/02/23 15:10
 */
export function add (parameter) {
  return axios({
    url: '/param/add',
    method: 'post',
    data: parameter
  })
}

/**
 * 编辑参数分类
 *
 * @author taidi
 * @date 2021/02/23 15:10
 */
export function editDir (parameter) {
  return axios({
    url: '/param/editDir',
    method: 'post',
    data: parameter
  })
}
/**
 * 编辑参数
 *
 * @author taidi
 * @date 2021/02/23 15:10
 */
export function edit (parameter) {
  return axios({
    url: '/param/edit',
    method: 'post',
    data: parameter
  })
}

/**
 * 删除参数分类
 *
 * @author taidi
 * @date 2021/02/23 15:10
 */
export function delDir (parameter) {
  return axios({
    url: '/param/delDir',
    method: 'post',
    params: parameter
  })
}
/**
 * 删除参数
 *
 * @author taidi
 * @date 2021/02/23 15:10
 */
export function del (parameter) {
  return axios({
    url: '/param/del',
    method: 'post',
    params: parameter
  })
}
/**
 * 参数分类树
 */
export function getDirTree (params) {
  return axios({
    url: '/param/getDirTree',
    method: 'get',
    params: params
  })
}
/**
 * 获取所有参数
 *
 * @author taidi
 * @date 2021/02/23 15:10
 */
export function getAll (parameter) {
  return axios({
    url: '/param/getAll',
    method: 'get',
    params: parameter
  })
}
