<template>
  <a-card :bordered="false">
    <div class="table-page-search-wrapper">
      <a-form layout="inline">
        <a-row :gutter="48">

          <a-col :md="8" :sm="24">
            <a-form-item label="参数名称">
              <a-input v-model="queryParam.name" placeholder="请输入参数名称"/>
            </a-form-item>
          </a-col>

          <a-col :md="8" :sm="24">
            <a-form-item label="参数键名">
              <a-input v-model="queryParam.keyName" placeholder="请输入参数键名"/>
            </a-form-item>
          </a-col>

          <a-col :md="!advanced && 8 || 24" :sm="24">
            <span class="table-page-search-submitButtons" :style="advanced && { float: 'right', overflow: 'hidden' } || {} ">
              <a-button type="primary" @click="$refs.table.refresh(true)">查询</a-button>
              <a-button style="margin-left: 8px" @click="resetForm">重置</a-button>
            </span>
          </a-col>

        </a-row>
      </a-form>
    </div>

    <div class="table-operator">
      <a-button type="primary" @click="$refs.actionForm.add(currentId)" icon="plus">新增</a-button>
    </div>

    <s-table
      ref="table"
      size="small"
      :columns="columns"
      :data="loadData"
      :rowKey="(record) => record.id"
    >
      <span slot="action" slot-scope="text, record">
        <a @click="$refs.actionForm.edit(record)">编辑</a>
        <a-divider type="vertical"/>
        <a-popconfirm placement="topRight" title="确认删除？" @confirm="() => del(record)">
          <a>删除</a>
        </a-popconfirm>
      </span>
    </s-table>

    <action-form ref="actionForm" @ok="handleOk"/>
  </a-card>
</template>

<script>
  import { STable } from '@/components'
  import { getAll, del } from '@/api/system/parameter'
  import actionForm from './acParameterActionForm'

  export default {
    components: {
      STable,
      actionForm
    },
    props: {
      currentId: {
        type: String,
        default () {
          return ''
        }
      }
    },
    data () {
      return {
        advanced: false, // 高级搜索 展开/关闭
        queryParam: {}, // 查询参数
        columns: [ // 表头
          {
            title: '参数名称',
            dataIndex: 'name'
          },
          {
            title: '参数键名',
            dataIndex: 'keyName'
          },
          {
            title: '参数值',
            dataIndex: 'value'
          },
          {
            title: '分类名称',
            dataIndex: 'dirName',
            ellipsis: true,
          },
          {
            title: '备注',
            dataIndex: 'remark',
            ellipsis: true,
          },
          {
            title: '排序',
            dataIndex: 'sort',
            width: '50px',
            align:'center'
          },
          {
            title: '操作',
            dataIndex: 'action',
            width: '150px',
            scopedSlots: { customRender: 'action' }
          }
        ],
        // 加载数据方法 必须为 Promise 对象
        loadData: parameter => {
          this.$set(this.queryParam, 'dirId', this.currentId)
          return getAll(Object.assign(parameter, this.queryParam)).then((res) => {
            res.data = res['rows']
            res.totalCount = res['total']
            res.pageNo = parameter['pageNo']
            return res
          })
        }
      }
    },

    methods: {
      del (record) {
        del({ id: record.id }).then((res) => {
          if (res.code === 200) {
            this.$message.success('删除成功')
            this.$refs.table.refresh()
          }
        }).catch((err) => {
          console.error(err)
        })
      },
      toggleAdvanced () {
        this.advanced = !this.advanced
      },
      handleOk () {
        this.$refs.table.refresh()
      },
      /**
       * 部门发生变化时刷新
       * @param currentId 选中的部门id
       */
      deptRefresh () {
        this.$refs.table.refresh(true)
      },
      /**
       * 重置表单
       */
      resetForm () {
        this.queryParam = {
          realName: null,
          status: null,
          phone: null,
          defaultDeptId: this.queryParam.defaultDeptId
        }
      }
    }
  }
</script>

<style lang="less">
.table-operator {
  margin-bottom: 2px;
}
button {
  margin-right: 8px;
}
</style>
